import React from 'react';

function Work() {
  return (
    <div>
      <h1>My work</h1>
      <p>Text.</p>
    </div>
  );
}

export default Work;
